@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_buttons.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';

.HomeComponentContainer {
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .HomeComponentContainer {
  }
}

/* ---------------------------  Longer Mobile --------------------------- */
@media (max-width: $mobile-max-width) and (min-height: $shortmobile-max-height) {
  .HomeComponentContainer {
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .HomeComponentContainer {
    margin-left: 50px;
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) and (orientation: portrait) {
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .HomeComponentContainer {
  }
}
