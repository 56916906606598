@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';
@import 'src/titles.scss';
@import 'src/_buttons.scss';

.FeatureContainer {
  border-radius: $main-border-radius;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  h3 {
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  button {
    margin-top: 1em;
    align-self: center;
    border-radius: $main-border-radius;
    padding: 0.4em 0.8em;
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .FeatureContainer {
    h3 {
      font: $mobile-h3;
    }
    p {
      font: $mobile-p;
    }
    button {
      font: $mobile-small-button;
    }
  }
}
/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .FeatureContainer {
    h3 {
      font: $tablet-h3;
    }
    p {
      font: $tablet-p;
    }
    button {
      font: $tablet-small-button;
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .FeatureContainer {
    h3 {
      font: $desktop-h3;
    }
  }
}
