/* ------------ Common fonts  ----------- */
$language-select-font: 700 12px 'Comfortaa', sans-serif;
$menu-font: 700 16px 'Comfortaa', sans-serif;
$hobbies-font: 400 14px 'Comfortaa', sans-serif;
$footer-title: 400 16px 'Comfortaa', sans-serif;

/* ------------- Button Fonts ---------------  */
$button-font: 400 16px 'Comfortaa', sans-serif;
$button-mobile-font: 400 12px 'Comfortaa', sans-serif;

/* ----------Small Button Fonts -------------- */
$mobile-small-button: 400 14px 'Comfortaa', sans-serif;
$tablet-small-button: 400 16px 'Comfortaa', sans-serif;
$desktop-small-button: 400 20px 'Comfortaa', sans-serif;

/* ------------- H1 Fonts ---------------  */
$mobile-h1: 700 24px 'Comfortaa', sans-serif;
$tablet-h1: 700 30px 'Comfortaa', sans-serif;
$desktop-h1: 700 48px 'Comfortaa', sans-serif;

/* ------------- H2 Fonts ---------------  */
$mobile-h2: 700 18px 'Comfortaa', sans-serif;
$tablet-h2: 700 24px 'Comfortaa', sans-serif;
$desktop-h2: 700 36px 'Comfortaa', sans-serif;

/* ------------- H3 Fonts ---------------  */
$mobile-h3: 400 14px 'Comfortaa', sans-serif;
$tablet-h3: 400 16px 'Comfortaa', sans-serif;
$desktop-h3: 400 20px 'Comfortaa', sans-serif;

/* ------------- P Fonts ---------------  */
$mobile-p: 400 12px 'Open Sans', sans-serif;
$tablet-p: 400 14px 'Open Sans', sans-serif;
$desktop-p: 400 16px 'Open Sans', sans-serif;

/* ------------- Skills Fonts ---------------  */
$mobile-skill-font: 700 12px 'Comfortaa', sans-serif;
$desktop-tablet-skill-font: 700 16px 'Comfortaa', sans-serif;

/* ------------- Pitch Fonts ---------------  */
$mobile-pitch-font: 300 20px 'Open Sans', sans-serif;
$tablet-pitch-font: 300 24px 'Open Sans', sans-serif;
$desktop-pitch-font: 300 30px 'Open Sans', sans-serif;

/* ------------- Quote Fonts ---------------  */
$mobile-quote-font: 400 14px 'Open Sans', sans-serif;
$tablet-quote-font: 400 16px 'Open Sans', sans-serif;
$desktop-quote-font: 300 19px 'Open Sans', sans-serif;

/* ------------- LanguageSkills Fonts ---------------  */
$mobile-language-skills-font: 700 16px 'Comfortaa', sans-serif;
$tablet-language-skills-font: 700 18px 'Comfortaa', sans-serif;
$desktop-language-skills-font: 700 20px 'Comfortaa', sans-serif;

/* ------------- Technology Fonts ---------------  */
$mobile-technology-font: 300 14px 'Open Sans', sans-serif;
$tablet-technology-font: 300 16px 'Open Sans', sans-serif;
$desktop-technology-font: 300 18px 'Open Sans', sans-serif;
