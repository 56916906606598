@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';
@import 'src/_buttons.scss';

.ProjectGeneralContainer {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  align-items: center;
  padding: 30px;

  &-ReturnLink {
    position: relative;
    align-self: flex-start;
    margin-top: 6px;
  }

  &-Title {
    color: $main-darkblue;
    margin: 0.5em;
  }
  &-Subtitle {
    margin-bottom: 0.3em;
  }
  &-QuoteContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    img {
      height: auto;
    }
    h4 {
      color: $main-darkblue;
      text-align: center;
    }
  }

  &-TechnologiesContainer {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    p:nth-child(1n) {
      border: 2px solid $main-palegreen;
      border-radius: $main-border-radius;
      padding: 0.2em 0.6em;
      margin: 5px;
    }
    p:nth-child(2n) {
      border: 2px solid $main-darkblue;
    }
    p:nth-child(3n) {
      border: 2px solid $main-lilly;
    }
  }

  &-Image {
    max-width: 100%;
    height: auto;
    border-radius: $main-border-radius;
    margin-bottom: 20px;
  }
  &-Text {
    color: $main-darkblue;
    &-Paragraph {
      margin-bottom: 1em;
    }
  }
}
/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .ProjectGeneralContainer {
    padding: 10px 15px;
    &-ReturnLink {
      &-Image {
        width: 30px;
        height: auto;
      }
    }

    &-Title {
      font: $mobile-h1;
      margin: 10px;
    }

    &-QuoteContainer {
      img {
        width: 10px;
      }
      h4 {
        font: $mobile-quote-font;
      }
    }

    &-TechnologiesContainer {
      margin-bottom: 10px;
      p:nth-child(1n) {
        border-width: 1px;
        font: $mobile-technology-font;
        padding: 0.1em 0.4em;
      }
    }
    &-Text {
      font: $mobile-p;
    }
  }
}
/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .ProjectGeneralContainer {
    &-Title {
      font: $tablet-h1;
    }

    &-QuoteContainer {
      img {
        width: 12px;
      }
      h4 {
        font: $tablet-quote-font;
      }
    }
    &-Text {
      font: $tablet-p;
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .ProjectGeneralContainer {
    &-Title {
      font: $desktop-h1;
    }

    &-QuoteContainer {
      img {
        width: 14px;
      }
      h4 {
        font: $desktop-quote-font;
      }
    }
    &-Text {
      font: $desktop-p;
    }
  }
}
