@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';
@import 'src/_buttons.scss';

.ProjectContainer {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: $main-border-radius;
  color: $main-white;
  // animation: cardAppear 1s ease-in-out;
  // -webkit-animation: cardAppear 1s ease-in-out;

  &-CardFront {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-Title {
      color: inherit;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    &-Image {
      width: 90%;
      height: auto;
    }
    &-TechnologiesContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 10px;
      &-Technology {
        margin: 0.2em;
        padding: 0.1em 0.4em;
        border: 1px solid $main-white;
        border-radius: $main-border-radius;
      }
    }
    &-Button {
      @include simple-button;
      margin: 1em;
      text-decoration: none;
      color: $main-darkblue;
    }
  }
  &-CardBack {
    display: none;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-Title {
      color: inherit;
    }
    &-Image {
      width: 90%;
      height: auto;
    }
    &-Button {
      @include simple-button;
      margin: 1em;
      text-decoration: none;
      color: $main-darkblue;
    }
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .ProjectContainer {
    &-CardFront {
      &-Title {
        font: $mobile-h2;
      }
      &-Description {
        font: $mobile-p;
        text-align: center;
        width: 75%;
      }
    }
  }
}
/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .ProjectContainer {
    &-CardFront {
      &-Title {
        font: $tablet-h2;
      }
      &-Description {
        font: $tablet-p;
        text-align: center;
        width: 80%;
      }
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) and (hover: hover) {
  .ProjectContainer {
    position: relative;
    min-width: 300px;
    min-height: 300px;

    &:hover > &-CardBack {
      opacity: 1;
    }

    &-CardFront {
      width: 100%;
      align-items: center;
      justify-content: center;
      &-Title {
        display: none;
      }
      &-Description {
        display: none;
      }
      &-Image {
        width: 250px;
        max-width: 85%;
        height: auto;
      }
      &-TechnologiesContainer {
        display: none;
      }
      &-Button {
        display: none;
      }
    }
    &-CardBack {
      background-color: inherit;
      border-radius: $main-border-radius;
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: 0.8s opacity ease-in-out;
      &-Title {
        font: $tablet-h2;
        margin: 0.5em 0.3em 0.3em 0.3em;
      }
      &-Separator {
        border-bottom: 1px solid $main-white;
        width: 70%;
      }
      &-TechnologiesContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 5px;
        &-Technology {
          margin: 0.2em;
          padding: 0.1em 0.4em;
          border: 1px solid $main-white;
          border-radius: $main-border-radius;
        }
      }
      &-Description {
        font: $desktop-p;
        text-align: center;
        margin: 0.4em;
        width: 95%;
      }
      &-Button {
        a {
          text-decoration: inherit;
          color: inherit;
        }
      }
    }
  }
}

/* ------------------ Animations -------------------- */
// @keyframes cardAppear {
//   0% {
//     transform: scale(0);
//     // opacity: 0;
//   }

//   @for $percent from 1% to 100% {
//     #{$percent } {
//       transform: scale($percent);
//       //opacity: $percent;
//     }
//   }
// }

// @-webkit-keyframes cardAppear {
//   0% {
//     -webkit-transform: scale(0);
//     // opacity: 0;
//   }

//   @for $percent from 1% to 100% {
//     #{$percent } {
//       -webkit-transform: scale($percent);
//       //opacity: $percent;
//     }
//   }
// }
