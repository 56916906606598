@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';

/* ---------------------------  Commont Styles --------------------------- */

.LanguagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $main-lightgrey;
  padding-bottom: 36px;

  &-Title {
    @include h2Title;
  }

  &-LanguageSkillsContainer {
    display: flex;
    flex-wrap: wrap;
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .LanguagesContainer {
    &-Title {
      font: $mobile-h2;
    }
    &-LanguageSkillsContainer {
      justify-content: center;
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .LanguagesContainer {
    &-Title {
      font: $tablet-h2;
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .LanguagesContainer {
    &-Title {
      font: $desktop-h2;
    }
    &-LanguageSkillsContainer {
      width: 70%;
    }
  }
}
