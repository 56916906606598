@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';

/* ---------------------------  Commont Styles --------------------------- */

.BioContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $main-white;

  &-Title {
    @include h2Title;
  }

  &-ImgTextContainer {
    display: flex;
    margin-top: 1em;

    &-Img {
      max-width: 100%;
      height: auto;
    }

    &-Text {
      margin: 0;
      padding: 1em;
      color: $main-white;
      background-color: $main-darkblue;
      &-Paragraph {
        padding: 0.3em 1.5em;
      }
    }
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .BioContainer {
    &-Title {
      font: $mobile-h2;
    }
    &-ImgTextContainer {
      flex-direction: column;
      &-Text {
        &-Paragraph {
          font: $mobile-p;
        }
      }
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .BioContainer {
    padding-bottom: 40px;
    &-Title {
      font: $tablet-h2;
    }
    &-ImgTextContainer {
      flex-direction: column;
      &-Text {
        &-Paragraph {
          font: $tablet-p;
        }
      }
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .BioContainer {
    &-Title {
      font: $desktop-h2;
    }

    &-ImgTextContainer {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &-Img {
        max-width: 40%;
        height: auto;
      }
      &-Text {
        max-width: 50%;
        &-Paragraph {
          padding: 0.3em 1.5em;
          font: $desktop-p;
        }
      }
    }
  }
}

@media (min-width: $desktop-min-width) and (orientation: portrait) {
  .BioContainer {
    &-ImgTextContainer {
      align-items: flex-start;
    }
  }
}
