@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';
@import 'src/titles.scss';

/* ---------------------------  Common styles --------------------------- */

//Contains all carousl items includding buttons, slider and navigation
.CarouselContainer {
  width: 90%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  &-Button {
    cursor: pointer;
  }
  &-ButtonLeft {
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 3;
  }
  &-ButtonRight {
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 3;
  }

  //Wrapper for a slider inorder to make all slides visible but hidden
  &-SliderContainer {
    //Contains slides a long track of slides
    overflow-x: hidden;
    &-Slider {
      width: 100%;
      display: flex;

      transition: all ease-in-out 1s;

      &-ImageContainer {
        flex-shrink: 0;
        width: 100%;
        position: relative;
        padding: 10px;

        &-Image {
          width: 100%;
          //to hide empty line at the bottom
          display: block;
          border-radius: $main-border-radius;
        }
        &-Caption {
          position: absolute;
          left: 50%;
          bottom: 10px;
          width: 60%;
          transform: translate(-50%, -50%);
          background-color: $translucent-white;
          color: $main-white;
          border-radius: $main-border-radius;
          padding: 0.8em;
          text-align: center;
        }
      }
    }
  }

  //small dots at the bottom to navigate
  &-Nav {
    display: flex;
    &-Indicator {
      cursor: pointer;
      background-color: $darker-grey;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: 2px;
      &:hover {
        background-color: $main-palegreen;
      }
    }
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .CarouselContainer {
    &-Button {
      display: none;
    }
    &-SliderContainer {
      position: relative;
      &-SwipableCover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 0;
      }
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
}
