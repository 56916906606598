@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';

/* ---------------------------  Commont Styles --------------------------- */

.ServiceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em;

  &-Image {
    width: auto;
    height: 60px;
    margin: 0.5em;
  }
  &-Title {
    text-transform: uppercase;
    text-align: center;
    color: $main-lilly;
    margin: 0.5em;
  }
  &-Text {
    margin: 0.5em;
    width: 80%;
    text-align: center;
    color: $main-darkblue;
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .ServiceContainer {
    &-Image {
      height: 50px;
    }
    &-Title {
      font: $mobile-h3;
    }
    &-Text {
      font: $mobile-p;
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .ServiceContainer {
    width: 33%;
    &-Title {
      font: $tablet-h3;
    }
    &-Text {
      font: $tablet-p;
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .ServiceContainer {
    &-Image {
      height: 90px;
    }
    &-Title {
      font: $desktop-h3;
    }
    &-Text {
      font: $desktop-p;
    }
  }
}
