@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_buttons.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';

.IntroductionAndPitchContainer {
  background-color: $main-darkblue;
  background-image: url('../../../assets/img/home_background_mobile.webp');
  background-repeat: no-repeat;
  background-position: left 0 bottom 0;
  background-size: contain;

  &-TextAndButtonContainer {
    &-MainTitle {
      color: $main-white;
      white-space: pre-line;
    }
    &-Pitch {
      margin-top: 20px;
      margin-bottom: 20px;
      color: $main-white;
    }
    &-ButtonsContainer {
      display: flex;

      &-DownloadButton {
        @include pale-green-button;
        color: $main-darkblue;
        text-decoration: none;
        margin-right: 10px;
      }
    }
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .IntroductionAndPitchContainer {
    height: 100vh;
    background-image: url('../../../assets/img/home_background_mobile.webp');
    background-position: left 0 bottom -50px;
    padding-top: 50px;

    &-TextAndButtonContainer {
      margin-top: 5vh;
      margin-left: 20px;
      margin-right: 20px;

      &-MainTitle {
        font: $mobile-h1;
      }
      &-Pitch {
        font: $mobile-pitch-font;
      }

      &-ButtonsContainer {
        &-DownloadButton {
          font: $button-mobile-font;
        }
      }
    }
  }
}

/* ---------------------------  Longer Mobile --------------------------- */
@media (max-width: $mobile-max-width) and (min-height: $shortmobile-max-height) {
  .IntroductionAndPitchContainer {
    padding-top: 13vh;

    &-TextAndButtonContainer {
      margin-left: 30px;
      margin-right: 30px;
      &-ButtonsContainer {
        &-DownloadButton {
          width: 45%;
          text-align: center;
          margin-bottom: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .IntroductionAndPitchContainer {
    height: 100vh;
    background-image: url('../../../assets/img/home_background_tablet.webp');
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    &-TextAndButtonContainer {
      width: 48%;
      margin-bottom: 30px;
      margin-right: 20px;
      &-MainTitle {
        font: $tablet-h1;
        margin-bottom: 25px;
      }
      &-Pitch {
        font: $tablet-pitch-font;
        margin-bottom: 25px;
      }
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) and (orientation: portrait) {
  .IntroductionAndPitchContainer {
    height: 50vh;
    &-TextAndButtonContainer {
      width: 50%;
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .IntroductionAndPitchContainer {
    background-image: url('../../../assets/img/home_background_desktop.webp');
    padding: 0;

    &-TextAndButtonContainer {
      margin-right: 50px;
      margin-bottom: 16vh;
      &-MainTitle {
        font: $desktop-h1;
      }
      &-Pitch {
        font: $desktop-pitch-font;
      }

      &-ButtonsContainer {
        &-DownloadButton {
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
        }
      }
    }
  }
}
/* ---------------------------  Desktop Portrait --------------------------- */
@media (min-width: $desktop-min-width) and (orientation: portrait) {
  .IntroductionAndPitchContainer {
    &-TextAndButtonContainer {
      margin-right: 50px;
      margin-bottom: 10vh;
      &-MainTitle {
        font: $desktop-h1;
      }
      &-Pitch {
        font: $desktop-pitch-font;
      }
    }
  }
}
