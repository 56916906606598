@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_fonts.scss';

@mixin simple-button {
  font: $button-font;
  padding: 0.4em 1em;
  border-radius: $main-border-radius;
}

@mixin pale-green-button {
  background-color: $main-palegreen;
  @include simple-button;
  cursor: pointer;

  &:hover {
    //TODO: decide what to do on hover
    background-color: $main-lilly;
  }
  &:active,
  &:focus {
    //TODO: decide what to do when active
    background-color: $main-lilly;
  }
}
