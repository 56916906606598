@import 'src/_colors.scss';
@import 'src/_fonts.scss';

.HamburgerButtonContainer {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  -webkit-appearance: none;

  &:active,
  &:hover,
  &:focus {
    outline: none;
    background-color: transparent;
  }

  &-Line {
    fill: none;
    stroke: #122744;
    stroke-width: 3;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &-Line1 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
  }
  &-Line2 {
    stroke-dasharray: 60 60;
    stroke-width: 3;
  }
  &-Line3 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
  }
  &-MenuOpenedLine1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 3;
  }
  &-MenuOpenedLine2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 3;
  }
  &-MenuOpenedLine3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 3;
  }
}
