@mixin h2Title {
  color: $main-darkblue;
  text-align: center;
  margin: 36px;
}

@mixin footerTitle {
  margin: 0.7em;
  padding-top: 0.2em;
  color: $main-lilly;
  text-transform: uppercase;
  font: $footer-title;
}

/* ---- UNDERLINED LINKS ---- */

@mixin lineBehind {
  content: ' ';
  position: absolute;
  border-radius: 3px;
  height: 40%;
  top: 50%;
  left: 0;
  right: 0;
}

@mixin underlinedTextContainer {
  position: relative;
  padding: 0.1em 0.3em;
  &::after {
    background-color: $main-lilly;
    @include lineBehind;
    transform-origin: left;
  }
}

@mixin underlinedTextContainerOnHover {
  position: relative;
  padding: 0.1em 0.3em;
  cursor: pointer;
  &::after {
    background-color: $main-lilly;
    @include lineBehind;
    transform: scaleX(0);
    transform-origin: left;
  }
  &:hover::after {
    transform: scaleX(1);
    transition: all 0.5s ease-in-out;
  }
}

@mixin underlinedTextContainerTransparent {
  position: relative;
  padding: 0.1em 0.3em;
  &::after {
    background-color: transparent;
    @include lineBehind;
  }
}
