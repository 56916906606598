@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_fonts.scss';

/* ---------------------------  Commont Styles --------------------------- */

.SkillBubbleContainer {
  padding: 0.1em;
}
