@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_fonts.scss';

.NavBarContainer {
  &-AllwaysVisibleMenuContainer {
    background-color: $main-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 1px $main-lightgrey;

    &-LogoContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      &-Logo {
        width: 40px;
        height: auto;
      }
    }
  }

  &-ClosedMenuContainer {
    background-color: $main-white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all ease-in-out 0.5s;
    box-shadow: 1px 1px 1px $main-lightgrey;

    &-MenuListContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 0;
      padding: 1em;

      &-MenuItem {
        text-decoration: none;
        font: $menu-font;
        text-transform: uppercase;
        color: $main-darkblue;
        text-align: left;
        margin-top: 0.5em;
        display: flex;
        align-items: center;
        opacity: 0;

        &:hover {
          color: $main-lilly;
        }
      }

      &-ActiveMenuItem {
        color: $main-lilly;
      }

      @for $item from 1 through 3 {
        &-VisibleMenuItem#{$item} {
          transform: scale(1);
          opacity: 1;
          transition: all ease-in-out 0.5s 0.5s * $item;
        }
      }
    }
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: 767px) {
  .NavBarContainer {
    &-AllwaysVisibleMenuContainer {
      position: fixed;
      z-index: 4;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      padding: 5px 20px;
      &-SocialLinksContainer {
        width: 40px;
        a {
          display: none;
        }
      }
    }
    &-ClosedMenuContainer {
      z-index: 0;
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      transform-origin: top;
      transform: scaleY(0);

      &-MenuListContainer {
        height: 100%;
        align-items: center;
        padding-bottom: 0.2em;
        &-SocialLinksContainerMobile {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: auto;
          margin-bottom: 0.2em;
          transform: scale(0);
          opacity: 0;

          a {
            margin: 0.6em 0.3em 0 0.3em;
            img {
              width: 25px;
            }
          }
        }
        &-SocialLinksContainerMobileAppear {
          transform: scale(1);
          opacity: 1;
          transition: all ease-in-out 0.7s 2s;
        }
      }
    }

    &-MenuVisible {
      z-index: 3;
      transform: scaleY(1);
      transition: all ease-in-out 0.5s;
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: 768px) {
  .NavBarContainer {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    transition: all ease-in-out 0.5s;
    z-index: 0;

    &-AllwaysVisibleMenuContainer {
      flex-direction: column;
      width: 50px;
      padding: 10px 5px;

      &-SocialLinksContainer {
        display: flex;
        flex-direction: column;
        a {
          img {
            width: 20px;
          }
        }
      }
    }

    &-ClosedMenuContainer {
      transform-origin: left;
      transform: scaleX(0);
      transition: all ease-in-out 0.5s;
      &-MenuListContainer {
        &-SocialLinksContainerMobile {
          display: none;
        }
      }
    }
    &-MenuVisible {
      transform: scaleX(1);
    }
  }

  .OpenNavBarContainer {
    z-index: 6;
  }
}
