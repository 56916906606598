@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_fonts.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';

/* ---------------------------  Commont styles --------------------------- */

.ProjectsContainer {
  display: flex;
  &-ProjectIndContainer {
    display: flex;
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .ProjectsContainer {
    flex-direction: column;
    align-items: center;
    &-ProjectIndContainer {
      margin: 0 30px 30px 30px;
    }
  }
}
/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .ProjectsContainer {
    margin-left: 30px;
    margin-right: 30px;
    display: grid;
    margin-bottom: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(150px, auto);
    gap: 30px;
    grid-auto-flow: dense;
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) and (hover: hover) {
  .ProjectsContainer {
    display: grid;
    margin-bottom: 30px;
    min-height: 80vh;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(200px, auto);
    gap: 30px;
    grid-auto-flow: dense;

    &-ProjectIndContainer {
      width: 100%;
    }
    & > div:nth-child(2n) {
      grid-column: 2;
      grid-row-end: span 2;
    }
    & > div:nth-child(4n) {
      grid-column: 1;
      grid-row-end: span 2;
    }
    & > div:nth-child(5n) {
      grid-column: 2;
    }
    & > div:nth-child(6n) {
      grid-column: 3;
      grid-row-end: span 2;
    }
  }
}
