@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_fonts.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';

.BallContainer {
  display: flex;

  &-Ball {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: relative;

    &-Title {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $main-white;
      font: $desktop-tablet-skill-font;
    }
  }

  &-Bubble {
    -webkit-animation: bubble-anim 39s ease-out infinite;
    animation: bubble-anim 39s ease-out infinite;
  }
}
.BallContainerInvisible {
  transform: scale(0);
}
.BallContainerVisible {
  transform: scale(1);
  transform-origin: center;
  transition: ease-in-out 1s;
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .BallContainer {
    &-Ball {
      &-Title {
        font: $mobile-skill-font;
      }
    }
  }
}

/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .BallContainer {
    &-Ball {
      &-Title {
        font: $desktop-tablet-skill-font;
      }
    }
  }
}

/* ---------------------------  Animations  --------------------------- */
// @-webkit-keyframes bubble-anim {
//   0% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }

//   20% {
//     -webkit-transform: scaleY(0.95) scaleX(1.05);
//     transform: scaleY(0.95) scaleX(1.05);
//   }

//   48% {
//     -webkit-transform: scaleY(1.1) scaleX(0.9);
//     transform: scaleY(1.1) scaleX(0.9);
//   }

//   68% {
//     -webkit-transform: scaleY(0.98) scaleX(1.02);
//     transform: scaleY(0.98) scaleX(1.02);
//   }

//   80% {
//     -webkit-transform: scaleY(1.02) scaleX(0.98);
//     transform: scaleY(1.02) scaleX(0.98);
//   }

//   97%,
//   100% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
// }

@keyframes bubble-anim {
  // 0% {
  //   -webkit-transform: scale(1);
  //   transform: scale(1);
  // }

  // 20% {
  //   -webkit-transform: scaleY(0.95) scaleX(1.05);
  //   transform: scaleY(0.95) scaleX(1.05);
  // }

  // 48% {
  //   -webkit-transform: scaleY(1.1) scaleX(0.9);
  //   transform: scaleY(1.1) scaleX(0.9);
  // }

  // 68% {
  //   -webkit-transform: scaleY(0.98) scaleX(1.02);
  //   transform: scaleY(0.98) scaleX(1.02);
  // }

  // 80% {
  //   -webkit-transform: scaleY(1.02) scaleX(0.98);
  //   transform: scaleY(1.02) scaleX(0.98);
  // }

  // 97%,
  // 100% {
  //   -webkit-transform: scale(1);
  //   transform: scale(1);
  // }

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  1.026% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
    transform: scaleY(0.95) scaleX(1.05);
  }

  2.4624% {
    -webkit-transform: scaleY(1.1) scaleX(0.9);
    transform: scaleY(1.1) scaleX(0.9);
  }

  3.4884% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
    transform: scaleY(0.98) scaleX(1.02);
  }

  4.104% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98);
  }

  4.9761%,
  5.13% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
