@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';

.PortfolioComonentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $main-white;
  &-Title {
    margin: 0.5em;
    color: $main-darkblue;
  }

  &-CategoriesContainer {
    display: flex;

    &-Category {
      cursor: pointer;
      margin: 0.5em;
      @include underlinedTextContainerTransparent;
      z-index: 2;
      p {
        position: relative;
        z-index: 2;
      }
    }
    &-ActiveCategory {
      margin: 0.5em;
      @include underlinedTextContainer;
      p {
        position: relative;
        z-index: 2;
      }
    }
  }
}

/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .PortfolioComonentContainer {
    margin-top: 50px;
    &-Title {
      margin-top: 50px;
      margin-bottom: 30px;
      font: $tablet-h1;
    }
  }
}
/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .PortfolioComonentContainer {
    margin-left: 50px;
    &-Title {
      font: $tablet-h1;
    }
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
  .PortfolioComonentContainer {
    &-Title {
      font: $desktop-h1;
    }
    &-CategoriesContainer {
      &-Category {
        margin: 0.5em;
        @include underlinedTextContainerOnHover;

        p {
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}
