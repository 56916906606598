@import 'src/_colors.scss';
@import 'src/_vars.scss';
@import 'src/_breakpoints.scss';
@import 'src/_mixins.scss';
@import 'src/_fonts.scss';

.FullProjectComonentContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $main-white;
}
/* ---------------------------  Mobile --------------------------- */

@media (max-width: $mobile-max-width) {
  .FullProjectComonentContainer {
    margin-top: 50px;
  }
}
/* ---------------------------  Tablet --------------------------- */
@media (min-width: $tablet-min-width) {
  .FullProjectComonentContainer {
    margin-left: 50px;
  }
}

/* ---------------------------  Desktop --------------------------- */
@media (min-width: $desktop-min-width) {
}
